import { H3, Table, Th } from "@collabodoc/component-library";
import { useCallback, useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled, { createGlobalStyle } from "styled-components";
import { device } from "../../Content/Style/devices";
import usePagination from "../../hooks/usePagination";
import { filterPatients } from "../../utils/patientListUtils";
import CollapsableRow from "./CollapsableRow";
import CreatePatientModal from "./CreatePatientModal";
import { PageHeader } from "./PageHeader";
import Pagination from "./Pagination";
import { SideMenu } from "./SideMenu";
import usePatientListFilters from "../../hooks/usePatientListFilters";
import { FILTER_KEYS } from "../../enums/enums";
import {
  PatientListItemStatus,
  PatientListPatientModel,
} from "../../apiClient";
import { PatientListContext } from "../../context/PatientListContext";
import Tour from "reactour";
import { GlobalContext } from "../../context/GlobalContext";

const GlobalStyle = createGlobalStyle`
    body {
        background-color: #FFFFFF;
    }
`;

const PatientListView = () => {
  const [searchText, setSearchText] = useState("");
  const [showSideMenu, setShowSideMenu] = useState(false);
  const { patientList, isLoadingPatientList } = useContext(PatientListContext);
  const { isFilterSelected } = usePatientListFilters();
  const showCompletedColumn = isFilterSelected(
    FILTER_KEYS.STATUS,
    PatientListItemStatus.Completed,
  );
  const filteredPatients = filterPatients(patientList, searchText);
  const { page, pageCount, pageItems, resetPagination, itemsPerPage } =
    usePagination<PatientListPatientModel>(filteredPatients, 10);
  const isTablet = useMediaQuery({ query: device.tablet });
  const isLaptopS = useMediaQuery({ query: device.laptopS });
  const isLaptopM = useMediaQuery({ query: device.laptopM });
  const isMobileL = useMediaQuery({ query: device.mobileL });
  const tourPlayed = localStorage.getItem("patientListTagTourPlayed");
  const [tourOpen, setTourOpen] = useState(!isTablet && !tourPlayed);

  const handleSearchInput = useCallback(
    (e: string) => {
      resetPagination();
      setSearchText(e);
    },
    [resetPagination],
  );

  const [showCreatePatientModal, setShowCreatePatientModal] = useState(false);

  const tourConfig = [
    {
      selector: "[data-tut=reactour__tag]",
      content: (
        <div>
          <H3>Tagga ärende</H3>
          En nyhet i den här versionen är möjligheten att sätta taggar på ett
          ärende.
          <br />
          <br />I den här kolumnen visas vilka taggar som finns på ett ärende.
          Håll muspekaren över taggarna för att se samtliga taggar på ärendet.
        </div>
      ),
    },
  ];

  const { showCareCenter } = useContext(GlobalContext);

  return (
    <>
      <Tour
        isOpen={tourOpen}
        steps={tourConfig}
        showNumber={false}
        showButtons={false}
        showNavigation={false}
        onRequestClose={() => {
          setTourOpen(false);
          localStorage.setItem("patientListTagTourPlayed", true.toString());
        }}
      />
      <GlobalStyle />
      <ListContent>
        <SideMenu
          showSideMenu={showSideMenu}
          setShowSideMenu={() => setShowSideMenu(false)}
          handleSearchInput={handleSearchInput}
        />
        <Content sideMenuOpen={showSideMenu}>
          <PageHeader
            setShowCreatePatientModal={setShowCreatePatientModal}
            setShowSideMenu={setShowSideMenu}
            showSideMenu={showSideMenu}
          />
          <TableWrapper>
            <Table>
              <StyledThead>
                <tr>
                  <ThMinWidth>Namn</ThMinWidth>
                  <ThMediumWide>Personnummer</ThMediumWide>
                  <ThWide>Boende</ThWide>
                  {!isMobileL && <ThWide>Avdelning</ThWide>}
                  {!isTablet && showCareCenter && <ThWide>Vårdcentral</ThWide>}
                  {!isLaptopS && <ThWide>Typ</ThWide>}
                  {!isLaptopM && <ThMediumWide>Senaste händelse</ThMediumWide>}
                  {!isLaptopS && showCompletedColumn && (
                    <ThMediumWide>Avslutades</ThMediumWide>
                  )}
                  {!isTablet && <ThNarrow></ThNarrow>}
                  {!isTablet && (
                    <ThMediumWide data-tut={"reactour__tag"}>Tagg</ThMediumWide>
                  )}
                  {!isTablet && <ThSmall>Roll</ThSmall>}
                </tr>
              </StyledThead>
              {pageItems.map((patient) => (
                <CollapsableRow
                  key={patient.id}
                  patient={patient}
                  isLoading={isLoadingPatientList}
                />
              ))}
            </Table>
          </TableWrapper>
          <Pagination
            numberOfPages={pageCount}
            currentPageNumber={page}
            itemsPerPage={itemsPerPage}
            totalItemCount={filteredPatients.length}
          />
          <CreatePatientModal
            showCreatePatientModal={showCreatePatientModal}
            setShowCreatePatientModal={setShowCreatePatientModal}
          />
        </Content>
      </ListContent>
    </>
  );
};

const ListContent = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0 0.75rem 0.75rem;
  flex: 5;

  @media ${device.laptopM} {
    display: ${(props: { sideMenuOpen: boolean }) =>
      props.sideMenuOpen && "none"};
  }
`;

const StyledTh = styled(Th)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  :last-child,
  :nth-last-child(2) {
    text-align: right;
  }

  @media ${device.tablet} {
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-size: clamp(12px, 2.4vw, 16px);

    :nth-last-child(2) {
      text-align: left;
    }
  }
`;

const ThMinWidth = styled(StyledTh)`
  min-width: 10rem;
`;

const ThNarrow = styled(StyledTh)`
  overflow: visible;
  min-width: 2.75rem;
  width: 2.75rem;
`;

const ThSmall = styled(StyledTh)`
  width: 8rem;
`;

const ThMediumWide = styled(StyledTh)`
  width: 10rem;
`;

const ThWide = styled(StyledTh)`
  min-width: 4rem;
  width: 18rem;
`;

const TableWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  overflow-y: auto;
  padding-right: 0.75rem;
`;

const StyledThead = styled.thead`
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
`;

export default PatientListView;
